import React from "react"
import LDVELHContent from "../../components/LDVELHContent"
import Website from "../../templates/Website"

interface Props {}

const MemoQuest: React.FC<Props> = () => {
  return (
    <Website>
      <LDVELHContent />
    </Website>
  )
}

export default MemoQuest
